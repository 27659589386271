@import "../../styles/helpers";

.dotBase {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.dotLarge {
  width: 10px;
  height: 10px;
}
